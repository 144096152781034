<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card
      class="py-6 px-6 mt-3"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <v-row>
        <v-col flex>
          <div
            class="back-to-projects"
            @click="handleBackClick"
          >
            <v-icon color="blue">
              mdi-chevron-left
            </v-icon>
            <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
              {{ $t('Back to Milestone Info') }}
            </p>
          </div>
        </v-col>
        <v-col
          v-if="showAddTesPlansView"
          cols="auto"
          class="pointer"
          @click="closeShowAddTesPlansView"
        >
          <v-icon>mdi-close</v-icon>
        </v-col>
      </v-row>
      <template v-if="showAddTesPlansView">
        <p class="text-start display-1 font-weight-bold my-2">
          {{ $t('testPlans') }}
        </p>
        <div class="mt-6 d-flex">
          <v-chip
            :class="{ 'blue--text': tableFilter === 'all' }"
            width="200px"
            :color="tableFilter === 'all' ? 'blue-light' : 'gray-light'"
            label
            @click="changeFilter('all')"
          >
            <div class="font-weight-bold px-2">
              {{ $t('all') }} <span class="ml-2">{{ items.length - selectedUserLength }}</span>
            </div>
          </v-chip>
          <div class="ml-2">
            <v-chip
              :class="{ 'blue--text': tableFilter === 'selected' }"
              width="200px"
              :color="tableFilter === 'selected' ? 'blue-light' : 'gray-light'"
              label
              @click="changeFilter('selected')"
            >
              <div class="font-weight-bold px-2">
                {{ $t('selected') }} <span class="ml-2">{{ selectedUserLength }}</span>
              </div>
            </v-chip>
          </div>
        </div>
        <v-row class="d-flex align-center justify-space-between mt-5">
          <v-col
            :cols="$vuetify.breakpoint.smAndUp ? 'auto' : 12"
            class="d-flex align-center"
          >
            <v-text-field
              v-model="searchFilter"
              type="text"
              color="blue"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search by name, email, etc."
              class="search_input"
              dense
              single-line
              filled
              hide-details
              clear-icon="body-2"
            />
          </v-col>
          <v-col
            flex
            class="d-flex justify-space-between align-center"
          >
            <ProjectUserFilter @applyFilters="applyFilters" />
            <div class="ml-auto">
              <SettingsMenu 
                table-type="milestoneTestPlan" 
              />
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="isAnyFilterApplied"
          class="mt-3"
        >
          <v-chip
            v-for="role in appliedFilters.roles"
            :key="role.id"
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            label
          >
            {{ $t('role') }}: {{ role.name }}
          </v-chip>
          <v-chip
            v-for="project in appliedFilters.projects"
            :key="project.id"
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            label
          >
            {{ $t('project') }}: {{ project.name }}
          </v-chip>
          <v-chip
            v-for="tag in appliedFilters.tags"
            :key="tag.id"
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            label
          >
            {{ $t('project') }}: {{ tag.title }}
          </v-chip>
          <v-btn
            class="text-capitalize mt-1"
            color="blue"
            text
            @click="clearAll"
          >
            {{ $t('clearAll') }}
          </v-btn>
        </v-row>
        <MilestoneTestPlanTable
          :plans-data="filteredMilestones"
          :table-filter="tableFilter"
          :filtered-headers="filteredHeaders"
          :filtered-items="filteredMilestones"
          @updateRow="updateUser"
        />
      </template>

      <v-row
        justify="end"
        class="mt-4"
      >
        <v-col cols="12">
          <v-flex class="mt-6 d-sm-flex justify-end">
            <v-btn
              v-if="showAddTesPlansView"
              dark
              large
              color="gray-100"
              class="text-capitalize font-weight-bold black--text mr-4 mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              @click="openCreateTestPlan"
            >
              <v-icon
                class="mr-1"
                size="16"
              >
                mdi-plus
              </v-icon> {{ $t('Create new test plan') }}
            </v-btn>
            <v-btn
              v-if="showAddTesPlansView && toBeSelectedUserLength && tableFilter === 'all'"
              dark
              large
              color="blue"
              class="text-capitalize font-weight-bold black--white mr-4 mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              @click="handleAddClick"
            >
              {{ $t('projects.create_project.add') }} {{ toBeSelectedUserLength }}
            </v-btn>
            <v-btn
              v-if="showAddTesPlansView && toBeSelectedUserLength && tableFilter === 'selected'"
              dark
              large
              color="blue"
              class="text-capitalize font-weight-bold black--white mr-4 mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              @click="handleRemoveClick"
            >
              {{ $t('projects.create_project.remove') }} {{ toBeSelectedUserLength }}
            </v-btn>
            <v-btn
              v-if="!showAddTesPlansView || (showAddTesPlansView && !toBeSelectedUserLength)"
              dark
              large
              color="blue"
              class="text-capitalize font-weight-bold mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              :disabled="isCreateDisabled"
              @click="createMilestone"
            >
              {{ $t('create Milestone') }}
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </v-card>
    <ProjectDiscardDialog
      v-model="showConfirmBackDialog"
      :title="$t('milestone.close_testPlan_view.title')"
      :content="$t('milestone.close_testPlan_view.content')"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmClick"
    />
    <RemoveTestPlanDialog
      v-model="showConfirmRemoveDialog"
      :title="$t('milestone.close_remove_dialog.title')"
      :content="$t('milestone.close_remove_dialog.title')"
      :content_part2="$t('milestone.close_remove_dialog.content_part1')"
      :btn_label="$t('milestone.close_remove_dialog.btn_label')"
      milestone_name=""
      color="primary"
      @close="handleCloseRemoveClick"
      @handleConfirmClick="handleConfirmRemoveClick()"
    />
    <AddTestPlanDialog
      v-model="showConfirmAddDialog"
      :title="$t('milestone.close_add_dialog.title')"
      :content="$t('milestone.close_add_dialog.title')"
      :content_part2="$t('milestone.close_add_dialog.content_part1')"
      :btn_label="$t('milestone.close_add_dialog.btn_label')"
      milestone_name=""
      color="primary"
      @close="handleCloseAddClick"
      @handleConfirmClick="handleConfirmAddClick()"
    />
  </v-container>
</template>

<script>
import ProjectDiscardDialog from '@/components/Project/ProjectDiscardDialog.vue';
import MilestoneTestPlanTable from '@/components/Milestone/MilestoneTestPlanTable.vue';
import ProjectUserFilter from '@/components/Milestone/MilestoneTestRunFilter.vue';
import RemoveTestPlanDialog from '@/components/Milestone/MilestoneEditRemoveDialog.vue';
import AddTestPlanDialog from '@/components/Milestone/MilestoneEditAddDialog.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import { testPlans } from '@/constants/data.js';
import {
  TestPlansTestRunsTableHeaderAll,
  TestPlansTableHeader,
} from '@/constants/grid.js';
import { createNamespacedHelpers, mapGetters, mapActions as projectMapActions } from 'vuex';
const { mapMutations, mapActions, mapState: mapMilestoneState } = createNamespacedHelpers('milestone');
const { mapActions: mapPlanActions, mapState: mapPlanState } = createNamespacedHelpers('plan');

export default {
  name: 'ProjectCreate',
  components: {
    ProjectDiscardDialog,
    MilestoneTestPlanTable,
    ProjectUserFilter,
    RemoveTestPlanDialog,
    AddTestPlanDialog,
    SettingsMenu,
  },
  data() {
    return {
      isCreateDisabled: false,
      imageSrc: '',
      plans: JSON.parse(JSON.stringify(testPlans)), // deep copy of testPlans
      form: {
        projectName: '',
        description: '',
        items: [],
      },
      isColumnFilter: false,
      showConfirmBackDialog: false,
      showConfirmRemoveDialog: false,
      showConfirmAddDialog: false,
      showAddTesPlansView: true,
      toBeSelectedUser: [],
      selectedUser: [],
      tableFilter: 'all',
      searchFilter: '',
      appliedFilters: {
        roles: [],
        projects: [],
        tags: [],
      },
      mainFilters: {
        selectedRoles: [],
        testCases: [0, 1000],
        items: [0, 100],
        selectedTags: [],
        dateRange: {
          start: '',
          end: '',
        },
        progress: [0, 100],
      },
      mainFilter: false,
      headers: TestPlansTableHeader,
    };
  },
  computed: {
    ...mapPlanState(['items']),
    ...mapMilestoneState(['selectedMilestone']),
    ...mapGetters({
      dynamicHeaders:'headers/dynamicHeaders'
    }),
    toBeSelectedUserLength() {
      return this.items.filter((user) => user.toBeSelected).length;
    },
    selectedUserLength() {
      return this.items.filter((user) => user.selected).length;
    },
    isAnyFilterApplied() {
      return this.appliedFilters.roles.length || this.appliedFilters.projects.length || this.appliedFilters.tags.length;
    },
    filteredHeaders() {
      const filtered = this.headers.filter((header) => header.checked);
      return filtered;
    },
    filteredMilestones() {
      if (this.searchFilter && this.mainFilter == false) {
        const filtered = this.items.filter((item) => this.matchesFilter(item));
        return filtered;
      } else if (this.mainFilter == true && !this.searchFilter) {
        return this.items.filter((item) => {
          if (this.mainFilters.selectedRoles.length > 0 && !this.mainFilters.selectedRoles.includes(item.priority)) {
            return false;
          }
          if (item.testPlans < this.mainFilters.testCases[0] || item.testPlans > this.mainFilters.testCases[1]) {
            return false;
          }
          if (this.mainFilters.dateRange.start && this.mainFilters.dateRange.end) {
            const itemDate = new Date(item.creationdate);
            const startDate = new Date(this.mainFilters.dateRange.start);
            const endDate = new Date(this.mainFilters.dateRange.end);
            if (itemDate < startDate || itemDate > endDate) {
              return false;
            }
          }
          if (this.mainFilters.selectedTags.length > 0 && !this.mainFilters.selectedTags.includes(item.tag)) {
            return false;
          }
          if (item.progress < this.mainFilters.items[0] || item.progress > this.mainFilters.items[1]) {
            return false;
          }
          return true;
        });
      } else if (this.mainFilter == true && this.searchFilter) {
        const mainFiltered = this.items.filter((item) => {
          if (this.mainFilters.selectedRoles.length > 0 && !this.mainFilters.selectedRoles.includes(item.priority)) {
            return false;
          }
          if (item.testPlans < this.mainFilters.testCases[0] || item.testPlans > this.mainFilters.testCases[1]) {
            return false;
          }
          if (this.mainFilters.dateRange.start && this.mainFilters.dateRange.end) {
            const itemDate = new Date(item.creationdate);
            const startDate = new Date(this.mainFilters.dateRange.start);
            const endDate = new Date(this.mainFilters.dateRange.end);
            if (itemDate < startDate || itemDate > endDate) {
              return false;
            }
          }
          if (this.mainFilters.selectedTags.length > 0 && !this.mainFilters.selectedTags.includes(item.tag)) {
            return false;
          }
          if (item.progress < this.mainFilters.items[0] || item.progress > this.mainFilters.items[1]) {
            return false;
          }
          return true;
        });
        const filtered = mainFiltered.filter((item) => this.matchesFilter(item));
        return filtered;
      }
      return this.items;
    },
    milestones() {
      let filtered = this.items.filter((item) => item.customFields.planstatus === this.filter);

      return filtered.map((item) => ({
        ...item,
        processedStatus: this.staticValues(item, TestPlansTestRunsTableHeaderAll, 'New'),
      }));
    },
  },
  watch: {
    currentOrg: 'refreshData',
    filteredMilestones(newValue) {
      if (newValue === 'SEARCH_BACKEND') {
        this.searchPlans();
      }
    },
    mounted() {
      this.refreshData();
    },
  },
  created() {
    if(!this.dynamicHeaders.milestoneTestPlan) {
      this.initializeHeaders({ type: 'milestoneTestPlan' });
    }
    this.headers = this.dynamicHeaders.milestoneTestPlan;
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    ...mapActions(['add']),
    ...mapPlanActions(['fetchItems']),
    ...mapMutations(['CLEAR_SELECTED_MILESTONE']),
    ...projectMapActions("headers", ['initializeHeaders']),
    createMilestone() {
      this.isCreateDisabled = true;
      let payload = this.selectedMilestone;

      payload.customFields.testPlans = this.items;

      this.add({ swal: this.$swal, handle: this.$route.params.handle, projectKey: this.$route.params.key, payload })
        .then(() => {
          this.CLEAR_SELECTED_MILESTONE()
          this.$router.push({
            name: 'Milestones',
            params: {
              handle: this.$route.params.handle,
              key: this.$route.params.key
            }
          });
        })
        .catch((error) => {
          this.isCreateDisabled = false;
          console.error('Failed to create Test Plan:', error);
        });
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.previewImage(file);
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage() {
      this.imageSrc = '';
    },
    handleBackClick() {
      this.showConfirmBackDialog = true;
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    handleConfirmClick() {
      this.showConfirmBackDialog = false;
      this.$router.replace({
        name: 'MilestoneCreate',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
        query: {
          activeEdit: true
        }
      });
    },
    handleRemoveClick() {
      this.showConfirmRemoveDialog = true;
    },
    handleConfirmRemoveClick() {
      this.showConfirmRemoveDialog = false;
      this.removePlans();
    },
    handleCloseRemoveClick() {
      this.showConfirmRemoveDialog = false;
    },
    handleAddClick() {
      this.showConfirmAddDialog = true;
    },
    handleConfirmAddClick() {
      this.showConfirmAddDialog = false;
      this.addPlans();
    },
    handleCloseAddClick() {
      this.showConfirmAddDialog = false;
    },
    closeShowAddTesPlansView() {
      this.showAddTesPlansView = false;
      this.toBeSelectedUser = [];
      this.$router.push({
        name: 'MilestoneCreate',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
      });
    },
    changeFilter(filter) {
      this.tableFilter = filter;
    },
    addPlans() {
      this.items.forEach((user) => {
        if (user.toBeSelected) {
          this.$set(user, 'selected', true);
          this.$set(user, 'toBeSelected', false);
        }
      });
    },
    removePlans() {
      this.items.forEach((user) => {
        if (user.toBeSelected) {
          this.$set(user, 'selected', false);
          this.$set(user, 'toBeSelected', false);
        }
      });
    },
    updateUser(user) {
      const index = this.items.findIndex((item) => item.id === user.id);
      if (index !== -1) {
        this.$set(user, 'selected', this.tableFilter === 'all');
        this.$set(user, 'toBeSelected', false);
      }
    },
    clearAll() {
      this.appliedFilters = {
        roles: [],
        projects: [],
        tags: [],
      };
    },
    applyFilters(filters) {
      this.mainFilters = filters;
      this.mainFilter = true;
    },
    staticValues(item, obj, defultValue) {
      return obj[item.customFields.state] || obj[defultValue];
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter.toLowerCase();

      const nameMatch = item.name.toLowerCase().includes(lowerCaseFilter);

      return nameMatch;
    },
    async searchPlans() {
      if (this.searchFilter) {
        await this.$store.dispatch('plan/searchTestPlan', {
          handle: this.currentOrg.handle,
          text: this.searchFilter,
          perPage: this.perPage,
          currentPage: this.currentPage,
        });
      } else {
        this.refreshData();
      }
    },
    refreshData() {
      this.fetchItems({ handle: this.$route.params.handle, projectKey: this.$route.params.key });
    },
    openCreateTestPlan() {
      this.showAddTesPlansView = true;
      this.$router.push({
        name: 'TestPlanCreate',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
        query: {
          page: 'MilestoneTestPlan'
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.back-to-projects {
  display: flex;
  cursor: pointer;
  width: max-content;
}

.search_input {
  width: 100%;

  @media screen and (min-width: 600px) {
    width: 300px;
  }
}

.project-logo {
  border-radius: 50%;
  border: 2px dashed grey;
  width: 150px;
  height: 150px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
}

.project-logo:hover {
  border-color: #2196f3;
}

.hovering .edit-icon,
.hovering .delete-icon {
  display: block;
}

.edit-icon,
.delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-30px);
  display: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.delete-icon {
  margin-left: 60px;
}

.project-logo:hover .edit-icon,
.project-logo:hover .delete-icon {
  display: block;
}
</style>
